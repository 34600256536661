<template>
  <v-app class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <div class="row">
              <div class="col-6">
                <h4>
                  Admission settings
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                  Admission settings
              </div>
                <div class="col-12">
                    <v-tabs class="justify-start" v-model="tab" vertical>
                      <v-tabs-slider color="yellow"></v-tabs-slider>
                      <v-tab class="justify-start"> Enrollment setting</v-tab>
                      <v-tab class="justify-start"> Admit card setting</v-tab>
                      <v-tab class="justify-start"> Offer letter setting</v-tab>
                      <v-tab class="justify-start"> File setting</v-tab>
                      <v-tab-item>
                        <enrollment-setting @refresh="getSetting"
                                            v-if="setting" :setting="setting" :pass_message="setting.pass_message" :fail_message="setting.fail_message"  :edit_setting="edit"  :enrollment_year_id="setting.enrollment_year_id"></enrollment-setting>
                      </v-tab-item>
                      <v-tab-item>
                        <admit-card-setting  v-if="setting"></admit-card-setting>
                      </v-tab-item>
                        <v-tab-item>
                            <offer-letter-setting v-if="setting"></offer-letter-setting>
                        </v-tab-item>
                      <v-tab-item>
                        <file-setting v-if="setting"></file-setting>
                      </v-tab-item>
                    </v-tabs>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EnrollmentSetting from "@/view/pages/setting/admission/EnrollmentSetting.vue";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import AdmitCardSetting from "@/view/pages/setting/admission/AdmitCardSetting.vue";
import OfferLetterSetting from "@/view/pages/setting/admission/OfferLetterSetting.vue";
import FileSetting from "@/view/pages/setting/admission/FileSetting.vue";
const siteSettingService=new SiteSettingService();
export default {

    components:{
      EnrollmentSetting,AdmitCardSetting,OfferLetterSetting,FileSetting
    },
    data(){
        return{
            tab:0,
            setting:null,
            edit:false,
        }
    },
    methods:{
        getSetting(){
            siteSettingService
            .show()
                .then(response => {
                    this.setting = response.data;
                    if (this.setting && this.setting.id) {
                        this.edit = true;
                    }
                })
                .catch(error => {

                })
                .finally(() => {

              })
        }
    },
    mounted() {
        this.getSetting();
    }
}
</script>
