<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <v-col cols="12">
                          <v-select :items="academicYears" dense item-text="year" item-value="id" label="Enrollment year"
                                    outlined v-model="enrollment_year_id">
                          </v-select>
                        </v-col>

                        <v-col cols="6">
                            <strong>
                                Online application pass message
                            </strong>
                            <ckeditor :config="editorConfig" v-model="pass_message"></ckeditor>
                        </v-col>

                        <v-col cols="6">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="ma-2 text-lowercase" v-for="(input, i) of pass_inputs" :key="i"
                                           color="success"
                                           outlined
                                           label
                                           dark
                                           v-bind="attrs"
                                           v-on="on"
                                           v-clipboard:copy="`{{${input}}}`"
                                           v-clipboard:success="onCopy"
                                           v-clipboard:error="onError">
                                        {{input}}
                                    </v-btn>

                                </template>
                                <span>Copy</span>
                            </v-tooltip>

                        </v-col>

                        <v-col cols="8">
                            <strong>
                                Online application fail message
                            </strong>
                            <ckeditor v-model="fail_message" :config="editorConfig"></ckeditor>
                        </v-col>

                        <v-col cols="4">
<!--                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">

                                    <v-btn class="ma-2 text-lowercase"
                                           v-for="(input, i) of fail_inputs" :key="i"
                                           color="success"
                                           outlined
                                           label
                                           dark
                                           v-bind="attrs"
                                           v-on="on"
                                           v-clipboard:copy="`{{${input}}}`"
                                           v-clipboard:success="onCopy"
                                           v-clipboard:error="onError">
                                        {{input}}
                                    </v-btn>


                                </template>
                                <span>Copy</span>
                            </v-tooltip>-->

                        </v-col>
                        <div v-if="options.length > 0">
                            <v-col md="12" v-for="(option,value) in options" :key="value" >
                                <div v-if="option.data_type ==='text'">
                                    <label for>{{ option.option_name }}</label><br/>
                                    <ckeditor  v-if="option.data_type =='text'" :config="editorConfig" v-model="option.value"></ckeditor>
                                </div>
                                <v-switch v-if="option.data_type ==='boolean'" v-model="option.value"
                                          :label="option.value ? 'Enabled tax on invoice ':'Disabled tax on invoice'">
                                </v-switch>
                                <v-text-field v-if="option.data_type ==='string'" :label="option.option_name" v-model="option.value" dense outlined></v-text-field>
                              <v-file-input v-if="option.data_type=='file'" dense outlined  :label="option.option_name" v-model="option.value" prepend-icon=""  prepend-inner-icon="mdi-paperclip"></v-file-input>
                              <img v-if="option && option.option_image_path && option.option_image_path['real']" :src="option.option_image_path['real']" class="mt-3 ml-3 border rounded" style="width:100px;height:100px;object-fit:contain;" alt="">
                            </v-col>
                        </div>
                        <div class="col-md-12 float-right">
                            <v-btn
                                :loading="isBusy"
                                @click="saveSetting"
                                class="text-white float-right btn btn-primary"
                                depressed
                            >Save settings
                            </v-btn>
                        </div>
                    </div>

                </div>
            </div>
          <v-snackbar
              v-model="snackbar"
              text
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  icon
                  small
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
    </div>
</template>
<script>
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import OptionService from "@/core/services/option/OptionService";

const option=new OptionService();
const siteSetting=new SiteSettingService();
const academicYear=new AcademicYearService();
export default {
    props:['setting','enrollment_year_id','edit_setting','pass_message','fail_message'],
    data(){
        return {
          text:null,
          isBusy:false,
          snackbar:false,
          options:[],
          academicYears:null,
          editorConfig: {
            versionCheck: false,
              toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList']]
          },
          pass_inputs:['first_name','last_name','symbol_no','interview_date','interview_time','scholarship']
        }
    },
    mounted() {
      this.getAcademicYears();
      this.getOptions();
    },
    methods:{
        getAcademicYears() {
            academicYear.paginate()
                .then(response => {
                    this.academicYears = response.data.data;
                });
        },
        saveSetting(){
            if (this.edit_setting) {
                this.updateSetting();
            }
        },
        updateSetting() {
           this.isBusy = true;
           let data = {
               enrollment_year_id:this.enrollment_year_id,
               pass_message:this.pass_message,
               fail_message:this.fail_message,
           };
            siteSetting.update(this.setting_id, data).then(response => {
                if(response.data.status == "OK") {
                  this.$emit('refresh');
                  this.updateOptionCustomization();
                }
               this.isBusy = false;
            }).catch(() => {
              this.isBusy = false
            });
        },
        updateOptionCustomization(){
          this.loading=true;
          let fd= this.generateFd();
            option
                .updateOptionByKey(fd)
                .then(response => {
                    this.$snotify.success("Information updated");
                    this.getOptions();
                })
                .catch(error => { })
                .finally(() => {
                    this.loading=false;
                });
        },
        getOptions(){
            let data={};
            data.name=[
                'ONLINE_ADMISSION_TITLE',
                'ONLINE_ADMISSION_SUBTITLE',
                'ONLINE_ADMISSION_APPLY_IMAGE',
                'ONLINE_ADMISSION_ENTRANCE_IMAGE',
                'ONLINE_ADMISSION_NOTES',
                'ONLINE_ADMISSION_CLOSED_CONTENT',
                'ENABLE_TAX_ADMISSION',
                'ADMISSION_URL'
            ];
            option
              .getByKeys(data)
              .then(response => {
                if(response.data){
                    this.options=response.data.options;
                }
              }).catch((err) => {})
        },

      generateFd() {
        let  formData = new FormData();
        for (const option of this.options) {
          if (option.data_type === 'file' && option.value instanceof File) {
            formData.append(option.name, option.value);
          } else {
            formData.append(option.name, option.value);
          }
        }
        return formData;
      },

      onCopy: function () {
        this.text = "You just copied input ";
        this.snackbar = true;
      },
      onError: function () {
        this.text = "Failed to copy input";
        this.snackbar = true;
      },
    },

}
</script>
