<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div v-if="options.length > 0">
              <v-col md="12" v-for="(option,value) in options" :key="value" >
                <div v-if="option.data_type ==='boolean'">
                  <label for>{{ option.option_name }}</label><br/>
                  <v-switch
                      v-model="option.value"
                      :label="option.value ? 'Enable' : 'Disable'"
                  ></v-switch>
                </div>

              </v-col>
            </div>
            <div class="col-md-12 float-right"  v-if="options.length > 0">
              <v-btn :loading="loading"
                     @click="saveSetting"
                     class="text-white float-right btn btn-primary"
                     depressed>Save settings
              </v-btn>
            </div>
            <div v-show="options.length === 0">
              This information is not working.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OptionService from "@/core/services/option/OptionService";
const option=new OptionService();
export default {
  data(){
    return{
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList']]
      },
      options:[],
      loading:false,
    }
  },
  methods:{
    optionNeedToBeRendered(){
      let data= [
        'ENABLE_SCHOLARSHIP',
        'ENABLE_SCHOLARSHIP_REMARKS',
        'ENABLE_INTERVIEW_DATE',
        'ENABLE_INTERVIEW_TIME',

      ];
      return data;
    },
    getOptions(){
      let data={};
      data.name=this.optionNeedToBeRendered();
      option
          .getByKeys(data)
          .then(response => {
            if(response.data){
              this.options=response.data.options;

            }
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    saveSetting(){

      this.loading=true;
      option
          .updateOption(this.options)
          .then(response => {
            this.getOptions();
            this.$snotify.success("Information updated");
          })
          .catch(error => { })
          .finally(() => {
            this.loading=false;
          });
    },
  },
  mounted() {
    this.getOptions();
  }
}
</script>
